import React, { useCallback, useState } from 'react'
import { array, func, string } from 'prop-types'

import { Legend } from '../Legend'

import { ErrorMessage, InputCheckbox, Wrapper, WrapperCheckbox } from './styles'

const propTypes = {
  'data-testid': string,
  error: string,
  id: string,
  label: string,
  name: string.isRequired,
  onChange: func,
  options: array,
  selectedOptions: array,
}

const defaultProps = {
  'data-testid': 'checkbox',
  id: 'checkbox',
}

const Checkbox = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestid,
    error,
    id,
    label,
    name,
    onChange,
    options,
    selectedOptions: defaultSelected,
  } = props
  const [selectedOptions, setSelectedOptions] = useState(defaultSelected || [])

  const handleChange = useCallback(
    evt => {
      if (evt?.target?.checked)
        setSelectedOptions([...selectedOptions, evt?.target?.value])
      if (!evt?.target?.checked)
        setSelectedOptions(
          selectedOptions.filter(item => item !== evt?.target?.value),
        )

      onChange(selectedOptions)
    },
    [onChange, selectedOptions],
  )

  return (
    <Wrapper as="fieldset" data-testid={dataTestid}>
      <Legend data-testid={`${dataTestid}-legend`} as="legend">
        {label}
      </Legend>
      <WrapperCheckbox onChange={handleChange}>
        {options?.map(({ label: checkboxLabel, value }, index) => (
          <InputCheckbox
            key={`CreditasCheckbox-${name}-${index}`}
            id={`${id}-${name}-${index}`}
            label={checkboxLabel}
            name={name}
            value={value}
            ref={ref}
            defaultChecked={selectedOptions.includes(value)}
          />
        ))}
      </WrapperCheckbox>
      <ErrorMessage as="span">{error}</ErrorMessage>
    </Wrapper>
  )
})

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
