import { Checkbox } from '@creditas-ui/checkbox'
import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { HelperText } from '../HelperText'

const space = css`
  margin-top: 24px;
`

export const Wrapper = styled.fieldset`
  margin-bottom: 24px;
  width: 100%;

  ${media.up('5xl')} {
    margin-bottom: 32px;
  }
`
export const InputCheckbox = styled(Checkbox)`
  input + div {
    transition: box-shadow 0.3s ease-in-out;
  }

  input:focus:not(:checked) + div {
    box-shadow: 0 0 4px 1px ${selectTheme('colors.primary.40')};
  }
`

export const WrapperCheckbox = styled.fieldset`
  label:not([hidden]) ~ :not([hidden]) {
    ${space};
  }
`

export const ErrorMessage = styled(HelperText)`
  ${space};
  display: inline-block;
`
